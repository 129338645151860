import React from 'react';
import _ from 'lodash';

const BreakCreator = (props) => {

  return (
    <React.Fragment>
      {_.times(props.num, (index) => (<br key={index}/>))}
    </React.Fragment>
  );
}

export default BreakCreator;