import {PYTHON, CPP} from './CourseType'

const initialState = {
    courseName: 'something'
}

const setCourseReducer = ( state = initialState, action ) => {
  switch (action.type) {
    case PYTHON: return {
      ...state,
      courseName: "Python"
    }

    case CPP: return {
      ...state,
      courseName: "C++"
    }

    default: return state
  }
}

export default setCourseReducer