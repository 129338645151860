import React, { useState, useEffect } from 'react';
import classes from './CreateProblem.module.scss';
import BreakCreator from '../../../../utils/BreakCreator/BreakCreator'
import SubmitModal from './SubmitModal/SubmitModal';
import { useTranslation　} from 'react-i18next';

const CreateProblem = () => {
  
  // Define Language Settings
  const [t, i18n] = useTranslation();
  const [lang, setLang] = useState('');
  
  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang, i18n]);

  function handleCLick() {
    setLang(lang === 'en' ? 'jp' : 'en');
  }
  
  // Define Modal Settings
  const [show, setShow] = useState(false);
  const hideModal = () => { setShow(false); }
  const showModal = () => { setShow(true); }

  // Define Variables
  const qType = ['programming', 'mc', 'debugging', 'rearranging'];
  const [autoId, setAutoId] = useState("");
  const [courseName, setCourseName] = useState("");
  const [questionName, setQuestionName] = useState("");
  const [questionType, setQuestionType] = useState("");
  const [questionStatement, setQuestionStatement] = useState("");
  const [questionImage, setQuestionImage] = useState("");
  const [answerCode, setAnswerCode] = useState("");
  const [testCases, setTestCases] = useState("");
  const [expectedOutcome, setExpectedOutcome] = useState("");
  const [course, setCourse] = useState([]);
  const [courseId, setCourseId] = useState([]);
  
  // const progLang = ['Python', 'C++'];
  // const [language, setLanguage] = useState("");

  // Get Course Data
  useEffect(() => {
    var link;
    if (process.env.NODE_ENV === 'production') {
      link = 'https://enfty.herokuapp.com/api/courses/'
    } else {
      link = 'http://127.0.0.1:8000/api/courses/'
    }
    fetch(link, {
    // fetch('http://127.0.0.1:8000/api/courses/', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(response => response.json())
    .then(data => {
      // Create an Array of Courses Available in the Database
      const courseTemp = [];
      const courseIdTemp = [];
      for (let i=0; i < data.length; i++) {
        courseTemp.push(data[i].name)
        courseIdTemp.push(data[i].id)
      }
      setCourse(courseTemp);
      setCourseId(courseIdTemp);
    })
    .catch((error) => {
      console.error('Error:', error);
    });
  }, [])

  // Get Number of Questions to Automate Question ID Generation
  useEffect(() => {
    var link;
    if (process.env.NODE_ENV === 'production') {
      link = 'https://enfty.herokuapp.com/api/questions/'
    } else {
      link = 'http://127.0.0.1:8000/api/questions/'
    }
    fetch(link, {
    // fetch('http://127.0.0.1:8000/api/questions/', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(response => response.json())
    .then(data => { 
      const questionIdTemp = [];
      for (let i=0; i < data.length; i++) {
        questionIdTemp.push(data[i].questionid)
      }
      // console.log(questionIdTemp)
      setAutoId(Math.max(...questionIdTemp))
      // console.log(autoId)
      // setAutoId(data[Object.keys(data).pop()].questionid) 
    })
    .catch((error) => { 
      console.error('Error:', error); 
    });
  }, [])

  // Create a Question
  const createQuestion = () => {
    // Determine Course ID of the Newly Created Question
    let selectedCourseId = courseId.filter((_, i) =>
      course[i] == courseName
    ).pop();

    // Organise Newly Created Question Data
    const newData = {
      "questionid": Number(autoId+1),
      "questionName": questionName,
      "questionType": questionType,
      "course": selectedCourseId,
      "description": questionStatement,
      "questionImage": questionImage,
      "code": answerCode,
      "testCases": testCases,
      "expectedOutcome": expectedOutcome
    }

    console.log(JSON.stringify(newData))
    
    var link;
    if (process.env.NODE_ENV === 'production') {
      link = 'https://enfty.herokuapp.com/api/questions/'
    } else {
      link = 'http://127.0.0.1:8000/api/questions/'
    }
    fetch(link, {
    // fetch('http://127.0.0.1:8000/api/questions/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(newData),
    })
    .then(response => { 
      response.json();
      if (response.ok) {
        showModal(); // Show Modal When Post Request is Successful
      } else {
        alert(t('submitAlert'));
      }
    })
    .catch((error) => { 
      console.error('Error:', error); 
    });
  }

  return (
  <div className={classes.creator}>
      <SubmitModal 
        show={show} 
        handleClose={() => { 
          hideModal(); 
          setCourseName(""); setQuestionType(""); setQuestionName(""); setQuestionStatement(""); setAnswerCode(""); setTestCases(""); setExpectedOutcome("");
          window.location.reload();
        }}
        questionId={autoId+1} 
        questionName={questionName} 
      />
      <div className={classes.inputField}>
        {/* Select Course */}
        <select className={classes.pulldown} size="1" required style={{fontFamily:'Ubuntu'}} value={courseName} onChange={e => setCourseName(e.target.value)}>
          <option value="0">{t('selectCourse')}</option>
          {[...Array(course.length).keys()].map((i) => {
          return ( 
            <React.Fragment key={i} >
              <option value={course[i]}>{course[i]}</option>
            </React.Fragment>);
          })}
        </select>

        {/* Select QuestionType */}
        <select id="qType" className={classes.pulldown} size="1" required style={{fontFamily:'Ubuntu'}} value={questionType} onChange={e => setQuestionType(e.target.value)}>
          <option value="0">{t('selectQuestionType')}</option>
          {[...Array(qType.length).keys()].map((i) => {
          return ( 
            <React.Fragment key={i} >
              <option value={qType[i]}>{qType[i]}</option>
            </React.Fragment>);
          })}
        </select>
        
        {/* Select Language
        <select className={classes.pulldown} size="1" required style={{fontFamily:'Ubuntu'}} value={language} onChange={e => setLanguage(e.target.value)}>
          <option value="0">Select Language:</option>
          {[...Array(progLang.length).keys()].map((i) => {
          return ( 
            <React.Fragment key={i} >
              <option value={progLang[i]}>{progLang[i]}</option>
            </React.Fragment>);
          })}
        </select> */}

        <BreakCreator num={1} />

        {/* Question Name */}
        <input id="qName" autoComplete="off" className={classes.shortInput} style={{fontFamily:'Ubuntu'}} type="text" value={questionName} onChange={e => setQuestionName(e.target.value)} placeholder={t('enterQuestionName')} />
        
        <BreakCreator num={1} />
        
        {/* Question Statement */}
        <textarea id="qStatement" style={{fontFamily:'Ubuntu'}} value={questionStatement} onChange={e => setQuestionStatement(e.target.value)} placeholder={t('enterQuestionStatement')}/>
        
        {/* Question Image */}
        <input id="qImage" autoComplete="off" className={classes.longInput} style={{fontFamily:'Ubuntu'}} type="text" value={questionImage} onChange={e => setQuestionImage(e.target.value)} placeholder={t('enterQuestionImage')} />
        
        {/* Answer Code */}
        <textarea id="answerCode" style={{fontFamily:'Ubuntu'}} value={answerCode} onChange={e => setAnswerCode(e.target.value)}placeholder={t('enterAnswerCode')}/>
      
        {/* Test Cases */}
        <input id="testCases" autoComplete="off" className={classes.longInput} style={{fontFamily:'Ubuntu'}} type="text" value={testCases} onChange={e => setTestCases(e.target.value)} placeholder={t('enterTestCases')} />

        {/* Expected Outcome */}
        <input id="expectedOutcome" autoComplete="off" className={classes.longInput} style={{fontFamily:'Ubuntu'}} type="text" value={expectedOutcome} onChange={e => setExpectedOutcome(e.target.value)} placeholder={t('enterExpectedOutcome')} />
      
      </div>
      
      {/* Buttons */}
      <div className={classes.buttons}>
        <button className={classes.lang} onClick={handleCLick}>{lang === 'en' ? 'JP' : 'EN' }</button>
        <button className={classes.preview}>{t('preview')}</button>
        <button className={classes.submit} onClick={createQuestion}>{t('submit')}</button>
    </div>
  </div>
  )
}

export default CreateProblem;