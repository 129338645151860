import React from 'react';
import classes from './Modal.module.scss';
import { Link } from 'react-router-dom';
import { useSelector} from 'react-redux';

const Modal = (props) => {
  
  const courseName = useSelector(state => state.courseName);
  const showHideClassName  = props.show ? classes.show : classes.hide;
  return (
    <React.Fragment>
      <div className={showHideClassName}>
        <div className={classes.modal} onClick={props.handleClose ? props.handleClose : props.show}>
          {/* <section className={classes.box} onClick={props.show ? props.show : props.handleClose }> */}
          <section className={classes.box} onClick={props.handleClose ? props.handleClose : props.show}>
            <h1>{props.courseName}</h1>
            <p>{props.description}</p>
            <Link to="/userpage/course" className={classes.startLink}><button className={classes.startButton}>START</button></Link>
            {/* <button className={classes.closeButton} onClick={props.handleClose}>&times;</button> */}
          </section>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Modal;