import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { useTranslation　} from 'react-i18next';
import classes from './DropdownMenu.module.scss';
// import { IoIosArrowDropdownCircle } from 'react-icons/io';
import { FiMenu } from 'react-icons/fi';

// the custom hook function must start with capital
const DropdownMenu = () => {
  const [t, i18n] = useTranslation();
  const [lang] = useState();

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [i18n]);

  const [isShow, setShowResults] = useState(false)
  const handleClick = () => isShow === true ? setShowResults(false) : setShowResults(true);

  return(
    <div className={classes.DropdownMenu} onClick={()=>handleClick()}>
        <div className={classes.icon} style={{color:'#ffffff'}}><FiMenu size={32} /></div>
      {isShow && 
      <div className={classes.dropBar}>
        <ul>
          <Link to="/"><li>{t('home')}</li></Link>
          <Link to="/overview" ><li>{t('overview')}</li></Link>
          {/* <Link to="/demo" ><li>{t('demo')}</li></Link> */}
          <Link to="/blog" ><li>{t('blog')}</li></Link>
          <Link to="/people" ><li>{t('people')}</li></Link>
          {/* <Link to="/login" ><li><div className={classes.login}>{t('launch')}</div></li></Link> */}
        </ul>
      </div>}
    </div>
  );
};

export default DropdownMenu;