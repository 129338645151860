import React, { useState, useEffect } from 'react';
import BreakCreator from '../../../../utils/BreakCreator/BreakCreator';
import {Link} from 'react-router-dom';
import icon from '../../../../asset/images/logo/enfty_user_icon_circle.png';
import classes from './LoginContents.module.scss';
import API from '../../../../api-service';
import { useCookies } from 'react-cookie';
import { useTranslation　} from 'react-i18next';

const LoginContents = () => {
  const [t, i18n] = useTranslation();
  const [lang] = useState();

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang, i18n]);

  const [ username, setUsername ] = useState('');
  const [ password, setPassword ] = useState('');
  
  const [token, setToken] = useCookies(['user-token'])
  
  useEffect(() => {
    if(token['user-token'] && token['user-token'] !== "undefined") window.location.href = '/userpage/myCourses';
  },[token])

  const loginClicked = () => {
      API.loginUser({username, password})
      .then( res => { 
        if (res !== undefined) {
          setToken('user-token', res.token, { path: '/' });
        }
      })
      .catch( err => console.log(err))
  }
  
  return (
    <div>
      <h2>{t('welcome_back')}</h2>
      <div className={classes.icon}><img src={icon} alt="" width='100'/></div>
      <BreakCreator num={1}/>
        <table>
          <tbody>
            <tr>
              <td><label>{t('email')}: </label></td>
              <td><input type="text" name="email" placeholder="" required onChange={evt=>setUsername(evt.target.value)}></input></td>
            </tr>
            <tr>
              <td><label>{t('pass')}: </label></td>
              <td><input type="password" name="password" placeholder="" required onChange={evt=>setPassword(evt.target.value)}></input></td>
            </tr>
          </tbody>
        </table>
        <div className={classes.help}>
          <Link to="/login" > {t('forgot_pass')} </Link>
          {/* <Link href='mailto:enfty.inc@gmail.com' > {t('forgot_pass')} </Link> */}
          {/* <a href='mailto:enfty.inc@gmail.com' > {t('forgot_pass')} </a> */}
        </div>
        <BreakCreator num={2}/>
        {/* <Link to="/userpage" className={classes.button} onClick={loginClicked}>{t('login')}</Link> */}
        <button className={classes.button} onClick={loginClicked}>{t('login')}</button>
        <BreakCreator num={2}/>
        <div className={classes.newuser}>
          &nbsp; {t('no_account')} &nbsp;
        </div>
        <BreakCreator num={3}/>
        <Link to="/login/new_account" className={classes.button}>{t('signup')}</Link>
        <BreakCreator num={3}/>
    </div>
  );

}

export default LoginContents;