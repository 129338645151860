import React from 'react';
import classes from './EditProblem.module.scss';

const EditProblem = () => {

  return (
    <div className={classes.edit}>
      <h1>Coming soon!</h1>
      <p>You can edit questions in the database here.</p>
    </div>
  );
}

export default EditProblem;