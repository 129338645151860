import React, { useEffect, useState } from 'react';
import classes from './CreatorLoginPage.module.scss';
import { useCookies } from 'react-cookie';
import API from '../../../../api-service';
import BreakCreator from '../../../../utils/BreakCreator/BreakCreator';
import { useTranslation　} from 'react-i18next';

const CreatorLoginPage = () => {
  const [t, i18n] = useTranslation();
  const [lang] = useState();
  
  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang, i18n]);

  const [token, setToken] = useCookies(['creator-token'])
  const [ username, setUsername ] = useState('');
  const [ password, setPassword ] = useState('');

  useEffect(() => {
    if(token['creator-token'] && token['creator-token'] !== "undefined") window.location.href = '/creatorpage/create';
  },[token])

  const loginClicked = () => {
    API.loginUser({username, password})
    .then( res => { 
      if (res !== undefined) { 
        setToken('creator-token', res.token, { path: '/' });
      }
    })
    .catch( err => console.log(err))
    console.log('creator logged in!')
  }

  return (
    <div className={classes.login}>
      <BreakCreator num={1}/>
      Welcome Creator!
      <BreakCreator num={2}/>
      <table>
          <tbody>
            <tr>
              <td><label>{t('email')}: </label></td>
              <td><input type="text" name="email" placeholder="" required onChange={evt=>setUsername(evt.target.value)}></input></td>
            </tr>
            <tr>
              <td><label>{t('pass')}: </label></td>
              <td><input type="password" name="password" placeholder="" required onChange={evt=>setPassword(evt.target.value)}></input></td>
            </tr>
          </tbody>
        </table>
        <BreakCreator num={3}/>
      <button onClick={loginClicked}>Login</button>
    </div>
  );
}

export default CreatorLoginPage;