import React from 'react';
import classes from './MyPointsPage.module.scss';
import BreakCreator from '../../../../utils/BreakCreator/BreakCreator';

const MyPoints = () => {

  var points = 0;
  // backend data

  return (
    <div className={classes.myPoints}>
      <h1>My Points</h1>
      <BreakCreator num={1}/>
      <p>
        You have earned &nbsp; <strong>{ points }</strong> &nbsp; points.
      </p>
      <BreakCreator num={1}/>
      <table>
        <tbody>
          <tr><th>Buy New Courses </th><td><button className={classes.buy}>BUY</button></td></tr>
          <tr><th>Buy New Avatars </th><td><button className={classes.buy}>BUY</button></td></tr>
        </tbody>
      </table>

    </div>
  );
}

export default MyPoints;