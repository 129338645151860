import React from 'react';
import classes from './MyAccountEditPage.module.scss';
import { Link } from 'react-router-dom';
import BreakCreator from '../../../../utils/BreakCreator/BreakCreator';
import icon from '../../../../asset/images/logo/enfty_user_icon_circle.png';

const MyAccountEdit = () => {
  var avatar = icon
  var name = 'Babe Ikomot';
  var username = 'muscle123';
  var email = 'codifier456@gmail.com';
  var birth = '29 Aug 1997';
  var gender = 'Sapiosexual';
  var about = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
  // backend data

  return (
    <div className={classes.myAccountEdit}>
      <table>
        <tbody>
          <tr><th><h1>My Account</h1></th><td><Link to="/userpage/myAccount"><button className={classes.Save}>Save</button></Link></td></tr>
        </tbody>
        <tbody>
          <tr><th>Avatar: </th><td><img src={avatar} alt='' width='50'/></td></tr>
          <tr><th>Name: </th><td><input type="text" placeholder={ name }/></td></tr>
          <tr><th>Username: </th><td><input type="text" placeholder={ username }/></td></tr>
          <tr><th>Email address: </th><td><input type="email" pattern=".+@globex.com" placeholder={ email }/></td></tr>
          <tr><th>Date of Birth: </th><td><input type="date" placeholder={ birth }/></td></tr>
          <tr><th>Gender: </th><td><input type="text" placeholder={ gender }/></td></tr>
        </tbody>
      </table>
      <BreakCreator num={1}/>
      <h3>About me: </h3>
      <textarea rows="4" cols="50" placeholder={ about } />
    </div>
  );
}

export default MyAccountEdit;