import React, { useEffect, useState } from 'react';
import classes from './Course.module.scss';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import API from '../../../../api-service';
// import BreakCreater from '../../../../utils/BreakCreator/BreakCreator';
import useMousePosition from '../../../../utils/useMousePosition/useMousePosition';
import useWindowDimensions from '../../../../utils/useWindowDimensions/useWindowDimensions';
import AceEditor from "react-ace";
import 'brace/mode/python';
import 'brace/theme/monokai';
import { FaPlay, FaTrashAlt } from 'react-icons/fa';
import { AiFillHome, AiFillBackward, AiFillForward, AiOutlineSolution, AiOutlinePlusCircle, AiOutlineMinusCircle, AiOutlineDown, AiOutlineUp } from 'react-icons/ai';
import { BiBookContent } from 'react-icons/bi';
import Cookies from 'js-cookie';
// import { useSelector} from 'react-redux';

const Course = (props) => {
  // Get Course Specific Token
  const courseId = Cookies.get('inCourse-token');
  
  // Get Window Dimensions
  const { width, height } = useWindowDimensions();
  
  // Get Cursor Coordinates
  const { x, y } = useMousePosition();

  // Define Variables
  const [ id, setId ] = useState('')
  const [ questionName, setQuestionName ] = useState('')
  const [ course, setCourse ] = useState('')
  const [ description, setDescription ] = useState('')
  const [ questionImage, setQuestionImage ] = useState('')
  const [ answerCode, setAnswerCode ] = useState('')
  const [ code, setCode ] = useState('');
  const [ output, setOutput ] = useState('');
  const [ isHoldingV, setIsHoldingV ] = useState(false);
  const [ isHoldingH, setIsHoldingH ] = useState(false);
  const [ isDropDown, setIsDropDown ] = useState(false);
  const [ isShowContent, setIsShowContent ] = useState(false);
  const [ isShowVideo, setIsShowVideo ] = useState(false);
  const [ isInputBox, setIsInputBox ] = useState(false);
  const [ selectedLang, setSelectedLang ] = useState('Python');
  const [ boxWidth, setBoxWidth ]   = useState([width*0.35, 10, width*0.65 - 10]);
  const [ boxHeight, setBoxHeight ] = useState([height*0.6, 10, height*0.4 - 70]);
  const [ lecture, setLecture ] = useState(1);

  // Define Languages Available to Compile
  const langs = ["Python", "C++", "Javascript"];

  // Get Questions According to the COURSE ID
  useEffect(() => {
    var link;
    if (process.env.NODE_ENV === 'production') {
      link = 'https://enfty.herokuapp.com/api/questions/'
    } else {
      link = 'http://127.0.0.1:8000/api/questions/'
    }
    fetch(link, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(response => response.json())
    .then(data => {
      const idTemp = [];
      const questionNameTemp = [];
      const courseTemp = [];
      const descriptionTemp = [];
      const questionImageTemp = [];
      const answerCodeTemp = [];
      for (let i=0; i < data.length; i++) {
        if (data[i].course == courseId) { 
          idTemp.push(data[i].questionid)
          questionNameTemp.push(data[i].questionName)
          courseTemp.push(data[i].course)
          descriptionTemp.push(data[i].description)
          questionImageTemp.push(data[i].questionImage)
          answerCodeTemp.push(data[i].code)
        }
      }
      setId(idTemp)
      setQuestionName(questionNameTemp)
      setCourse(courseTemp)
      setDescription(descriptionTemp)
      setQuestionImage(questionImageTemp)
      setAnswerCode(answerCodeTemp)
    })
    .catch((error) => {
      console.error('Error:', error);
    });
  }, [])
  console.log(id)
  // Compile the Code 
  const compileCode = () => {
    API.compileCode({code, "language":"PYTHON"})
    .then( res => setOutput(res['output']))
    .catch(err => console.log(err));
  }

  // Change Editor Code
  const deleteCode = () => { setCode('') } // Delete Code Typed in the Editor
  const showCode = (ans) => { setCode(ans) } // Show Answer Code in the Editor
  const onChange = (newCode) => { setCode(newCode) } // Update Code Typed in the Editor By Every Key Stroke

  // Control the Adjust Bar Between the Question and the Editor
  const handleEvent = (event) => {
    if (event.type === "mousedown") {
      setIsHoldingV(true);
    } else {
      setIsHoldingV(false);
      setBoxWidth( [x -  boxWidth[1]/2, 10,  width - x -  boxWidth[1]/2]);
    }
  }
  // Control the Adjust Bar Between the Editor and the Output Box
  const handleEvent2 = (event) => {
    if (event.type === "mousedown") {
      setIsHoldingH(true);
    } else {
      setIsHoldingH(false);
      setBoxHeight([y - 60 - boxHeight[1]/2, 10, height - y - boxHeight[1]/2]);
    }
  }

  // Control the Lecture Number
  const changeLecture = (lecture_num) => { 
    if (lecture_num <= 0) {
      setLecture(1);
    } else if (lecture_num >= id.length) {
      setLecture(id.length); 
    } else {
      setLecture(lecture_num);
    }
  }
  
  // Handle Show/Hide of Contents
  const showContent = () => { setIsShowContent(!isShowContent); } // Chapter Bar
  const showVideo = () => { setIsShowVideo(!isShowVideo); } // Tutorial Video
  const showInput = () => { setIsInputBox(!isInputBox); } // Input Box
  function onDropDown(lang) { setIsDropDown(!isDropDown); setSelectedLang(lang); } // Language Dropdown Menu

  return (
    <div className={classes.Course}>
      
      {/* TOP BUTTONS */}
      <div className={classes.buttonController}>
        {/* <Link to="/userpage/myCourses" onClick={alertUnsaved} style={{width: "100px", display:"inline-block"}}><button className={classes.compilerButton} onClick={showContent}><AiFillHome /></button></Link> */}
        <Link to="/userpage/myCourses" style={{width: "100px", display:"inline-block"}}><button className={classes.compilerButton}><AiFillHome /></button></Link>
        <div style={{width: "100px", display:"inline-block"}}><button className={classes.compilerButton} onClick={showContent}><BiBookContent /></button></div>
        <div style={{width: "100px", display:"inline-block"}}><button className={classNames (classes.compilerButton, classes.move)} onClick={() => {changeLecture(lecture-1); deleteCode();}}><AiFillBackward /></button></div>
        <div style={{width: "100px", display:"inline-block"}}><button className={classNames (classes.compilerButton, classes.move)} onClick={() => {changeLecture(lecture+1); deleteCode();}}><AiFillForward /></button></div>
        <div style={{width: "100px", display:"inline-block"}}><button className={classNames (classes.compilerButton, classes.run)}  onClick={compileCode} ><FaPlay /></button></div>
        <div style={{width: "100px", display:"inline-block"}}><button className={classNames(classes.compilerButton, classes.show)} onClick={() => showCode(answerCode[lecture-1])}><AiOutlineSolution /></button></div>
        <div style={{width: "100px", display:"inline-block"}}><button className={classNames(classes.compilerButton, classes.reset)} onClick={deleteCode}><FaTrashAlt /></button></div>
        <div style={{width: "175px", display:"inline-block"}}><button className={classes.language_dropdown1} onClick={() => onDropDown("select language")}>{selectedLang}</button></div>
      </div>


      {/* LANGUAGE DROPDOWN */}
      {isDropDown &&
      <div style={{width: "175px",  position:"fixed", left: "700px", top: "50px", zIndex: '1'}}>
        {langs.map(lang=> {
          return(<button key={lang} className={classes.language_dropdown} onClick={() => onDropDown(lang)}>{lang}</button>)
        })}
      </div>}
      
      {/* BODY */}
      <div className={classes.CourseBody} style={{height: `${height - 60}px`}}>
        
        {/* INSTRUCTION */}
        <div className={classes.instruction} style={!isHoldingV ? {width:`${boxWidth[0]}px`}: {width:`${x-boxWidth[1]/2}px`}} >
          {isShowVideo && 
          <React.Fragment>
            <div className={classes.video}>
              <iframe title="Q1" width="100%" height="300" src="https://www.youtube.com/embed/zcCiP3iVwIU" frameBorder="0" allow="picture-in-picture; fullscreen" onClick={showVideo}></iframe>
            </div>
            <button className={classes.hide} onClick={showVideo}><AiOutlineUp/></button>
          </React.Fragment>
          }
          {!isShowVideo &&
            <button className={classes.hide} onClick={showVideo}><AiOutlineDown/></button>
          }
          <h3 style = {{padding: '10px'}}>QUESTION {lecture} - {questionName[lecture-1]}</h3>
          <div style = {{padding: '10px', whiteSpace: "pre-wrap"}}>
            {description[lecture-1]}
            {/* <img src={questionImage[lecture-1]} width='100%' onError={e => e.target.style.display = 'none'} /> */}
            <img src={questionImage[lecture-1]} width='100%' />
          </div>
        </div>
        
        {/* HIDDEN CHAPTERS MENU*/}
        {isShowContent && 
        <div className={classes.content} style={{height: `${height - 80}px`}}>
          <p>Chapters</p>
          {[...Array(id.length).keys()].map((i) => {
            return (
            <React.Fragment key={i} >
              {/* <button onClick={() => {changeLecture(i+1); setIsShowContent(!isShowContent);}} >{i+1}. Question {i+1}</button> */}
              <button onClick={() => {changeLecture(i+1); setIsShowContent(!isShowContent); deleteCode();}} >{i+1}. {questionName[i]}</button>
            </React.Fragment>);
          })}
        </div>}
        
        {/* VERTICAL BAR */}
        <div className={classes.gridCtrlVertical} style={{width:`${boxWidth[1]}px`}} onMouseDown={ handleEvent } onMouseUp={ handleEvent }/>
        
        {/* EDITOR */}
        <AceEditor 
          className={classes.editor}
          width = {!isHoldingV ? `${boxWidth[2]}px` : `${width - x - boxWidth[1]/2}px`}
          height = {!isHoldingH ? `${boxHeight[0]}px`  : `${y - boxHeight[1]/2}px`}
          mode={selectedLang.toLowerCase()}
          theme="monokai"
          // value={answerCode[lecture-1]}
          value={code}
          fontSize={15}
          onChange={onChange}
          name="UNIQUE_ID_OF_DIV"
          editorProps={{ $blockScrolling: true }}
          style={isDropDown? {zIndex: -1} : {zIndex: 0}}
        />

        {/* HORIZONTAL BAR */}
        {!isHoldingV ?
        !isHoldingH ? <div 
        className={classes.gridCtrlHorizontal} 
        style={{position: 'absolute', 
        right:0, 
        top: `${boxHeight[0]}px`, 
        height:`${boxHeight[1]}px`, 
        width: `${boxWidth[2]}px`}} 
        onMouseDown={ handleEvent2 } 
        onMouseUp={ handleEvent2 }/>:
        <div 
        className={classes.gridCtrlHorizontal} 
        style={{position: 'absolute', 
        right:0, 
        top: `${y - 60 - boxHeight[1]/2}px`, 
        height:`${boxHeight[1]}px`, 
        width: `${boxWidth[2]}px`}} 
        onMouseDown={ handleEvent2 } 
        onMouseUp={ handleEvent2 }/> :
        <div 
        className={classes.gridCtrlHorizontal} 
        style={{position: 'absolute', 
        right:0, 
        top: `${boxHeight[0]}px`, 
        height:`${boxHeight[1]}px`, width: `${width - x - boxWidth[1]/2}px`}} 
        onMouseDown={ handleEvent2 } 
        onMouseUp={ handleEvent2 }/>
        }
        
        {/* CONSOLE */}
        <div 
          className={classes.console}
          style={
          !isHoldingV ?
          !isHoldingH ?{position:"absolute", bottom: `0`, right: '0', height: `${boxHeight[2]}px`, width: `${boxWidth[2]}px`}:
          {position:"absolute", bottom: `0`, right: '0', height: `${height - y  - boxHeight[1]/2}px`, width: `${boxWidth[2]}px`} :
          {position:"absolute", bottom: `0`, right: '0', height: `${boxHeight[2]}px`, width: `${width - x - boxWidth[1]/2}px`}} 
        >
          {isInputBox && <div style={{width:"50%"}}>
            <p className={classes.consoleButton}>INPUT <span onClick={showInput}><AiOutlineMinusCircle /></span></p>
            <textarea name="" id="" cols="30" rows="10" defaultValue={output}></textarea>
          </div>}
          <div style={isInputBox ? {width:"50%"} : {width:"100%"}}>
            {!isInputBox && <p className={classes.consoleButton} onClick={showInput}><AiOutlinePlusCircle/></p>}
            <p className={classes.consoleButton}>OUTPUT</p>
            <textarea disabled style={{cursor: 'not-allowed'}} name="" id="" cols="30" rows="10" defaultValue={output}></textarea>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Course;