import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom';
import BreakCreator from '../../../../utils/BreakCreator/BreakCreator';
import classes from './SignUp.module.scss';
import API from '../../../../api-service';
import { useTranslation　} from 'react-i18next';
import TermsModal from './TermsModal/TermsModal';
import SignUpModal from './SignUpModal/SignUpModal';

const SignUp = () => {
  const [t, i18n] = useTranslation();
  const [lang] = useState();

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang, i18n]);
  
  const [ username, setUsername ] = useState('');
  const [ confirmUsername, setConfirmUsername ] = useState('');
  const [ password, setPassword ] = useState('');
  const [ confirmPassword, setConfirmPassword ] = useState('');
  const [ emailError, setEmailError] = useState('');
  const [ passwordError, setPasswordError ] = useState('');
  
  // Define Terms Checkbox Settings
  const [ terms, setTerms ] = useState(false);
  const handleCheckBox = () => {
    if (terms === false) {
      setTerms(true);
    } else {
      setTerms(false);
    }
  }
  
  // Define Modal Settings
  const [show, setShow] = useState(false);
  const hideModal = () => { setShow(false); }
  const showModal = () => { setShow(true); }

  const [show2, setShow2] = useState(false);
  const hideModal2 = () => { setShow2(false); }
  const showModal2 = () => { setShow2(true); }

  // Define Email Verification Process 
  const validate = () => {
    var emailErrorTemp;
    if (username.length == 0) {
      emailErrorTemp = 'email cannot be blank';
      setEmailError('email cannot be blank');
    } else if (!username.includes("@")) {
      emailErrorTemp = 'invalid email';
      setEmailError('invalid email');
    } else if (username != confirmUsername) {
      emailErrorTemp = 'confirm email does not match';
      setEmailError('confirm email does not match');
    } else {
      emailErrorTemp = '';
      setEmailError('');
    }
    var passwordErrorTemp;
    if (password.length < 8) {
      passwordErrorTemp = 'password need to be longer than 8 characters';
      setPasswordError('password need to be longer than 8 characters');
    } else if (password != confirmPassword) {
      passwordErrorTemp = 'confirm password does not match';
      setPasswordError('confirm password does not match');
    } else {
      passwordErrorTemp = '';
      setPasswordError('');
    }
    
    if (emailErrorTemp.length == 0 && passwordErrorTemp.length == 0) {
      // console.log('validate success')
      return true;
    } else {
      // console.log('validate failure')
      return false; 
    }
  }

  const signUp = () => {
    if (validate() && terms) {
      var link;
      if (process.env.NODE_ENV === 'production') {
        link = 'https://enfty.herokuapp.com/api/users/'
      } else {
        link = 'http://localhost:8000/api/users/'
      }
      fetch(link, {
      // fetch(`http://localhost:8000/api/users/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({username, password})
      })
      .then(response => { 
        response.json();
        if (response.ok) {
          showModal2(); // Show Modal When Post Request is Successful
        }
      })
      .catch((error) => { 
        console.error('Error:', error); 
      });
    } else if (!terms) {
      alert(t('agree_error'))
    } else {
      return null
    }
  }

  // const signUp = () => {
  //   if (validate() && terms) {
  //     API.signUpUser({username, password})
  //     .then(res => {
  //       console.log(res)
  //       if (res.ok) {
  //         alert(t('signup_success'))
  //       }
  //     })
  //     .catch(err => console.log(err))
  //   } else if (!terms) {
  //     alert(t('agree_error'))
  //   } else {
  //     return null
  //   }
  // }

  return(
    <div className={classes.signUp}>
      {/* <h2>Welcome to <span style={{color:"#FF2672"}}>e</span>nfty!</h2> */}
      <h2>{t('welcome')}</h2>
      <hr size="1"/>
      <BreakCreator num={1}/>
        {t('enter_info')}
      <BreakCreator num={2}/>
      <table>
        <tbody>
          <tr>
            <td><label>{t('email')}: </label></td>
            <td><input type="text" name="email" placeholder="" required onChange={evt=>setUsername(evt.target.value)}></input></td>
          </tr>
          <tr>
            <td><label>{t('confirm_email')}: </label></td>
            <td><input type="text" name="email" placeholder="" required onChange={evt=>setConfirmUsername(evt.target.value)}></input></td>
          </tr>
        </tbody>
      </table>
      <div style={{ fontSize: 12, color: "red" }}>
        {emailError}
      </div>
      <BreakCreator num={1}/>
      <table>
        <tbody>
          <tr>
            <td><label>{t('pass')}: </label></td>
            <td><input type="password" name="password" placeholder="" required onChange={evt=>setPassword(evt.target.value)}></input></td>
          </tr>
          <tr>
            <td><label>{t('confirm_pass')}: </label></td>
            <td><input type="password" name="password" placeholder="" required onChange={evt=>setConfirmPassword(evt.target.value)}></input></td>
          </tr>
        </tbody>
      </table>
      <div style={{ fontSize: 12, color: "red" }}>
        {passwordError}
      </div>
      <BreakCreator num={1}/>
      <TermsModal show={show} handleClose={hideModal}/>
      <label>
        <input type="checkbox" onChange={handleCheckBox} />
        <span className={classes.checkbox_parts}><button className={classes.policy} onClick={showModal}>{t('agree')}</button> </span>
      </label>
      <BreakCreator num={2}/>
      {/* <Link to="/login" className={classes.button} onClick={signUpClicked}>{t('signup')}</Link> */}
      <button type="button" className={classes.button} onClick={signUp}>{t('signup')}</button>
      <BreakCreator num={3}/>
      <SignUpModal show={show2} handleClose={hideModal2} email={username} />
    </div>
  );
}

export default SignUp;