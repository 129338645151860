import React, { useEffect, useState, useRef } from 'react';
import classes from './Blog.module.scss';
import { useTranslation } from 'react-i18next';
import useWindowDimensions from '../../../utils/useWindowDimensions/useWindowDimensions';
import TextField from "@material-ui/core/TextField";
import test from '../../../asset/images/profiles/coming_soon.png';
import ReactPaginate from 'react-paginate';
import Contacts from './components/Contacts';


const Blog = () => {

  const universities = ['Georgia Tech', 'Princeton', 'Purdue', 'UC Berkeley'];

  const majors = [
    {en:'Actuarial Science', jp:' (保険数理学)'},
    {en:'Aerospace Engineering', jp:' (航空宇宙工学)'},
    {en:'Mechanical Engineering', jp:' (機械工学)'},
    {en:'Physics', jp:' (物理学)'},
    {en:'Professional Flight', jp:' (航空操縦学)'}
  ];

  const authors = ['0', 'Yasunori', '', 'Takanori', '', '', '', '', '', '', '10', '', '', '', '', '', 'Luka', 'Takeshi', 'Hami', '', 'Sota', '', '', '', '', '', '', '', '', '', '30',];

  const [posts, setPost] = useState([]);

  const [t, i18n] = useTranslation();
  const [lang] = useState();

  const [ offset, setOffset ] = useState(0); // 何番目のアイテムから表示するか
  const perPage = 6; // 1ページあたりに表示したいアイテムの数
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang, i18n]);


  useEffect(()=>{
    var link;
    if (process.env.NODE_ENV === 'production') {
      link = 'https://enfty.herokuapp.com/api/posts/'
    } else {
      link = 'http://127.0.0.1:8000/api/posts/'
    }
    fetch(link, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Token df4109aed49dd7144545308d4786681fb04e4d98'
      }
    })
    .then( resp => resp.json())
    .then( resp => setPost(resp.reverse()))
    .catch( error => console.log(error))
  }, [])

  const [selectedPost, setSelectedPost] = useState('');
  const itemClicked = item => e => {
    const url = ('/blog/' + item.slug);
    window.history.pushState(item, null, url);
    setSelectedPost(item);
    window.scrollTo(0, 0)
  }

  window.addEventListener('popstate', function(e) {
    const url = window.location.pathname;
    if (url == '/blog') {
      setSelectedPost('');
      window.location.reload();
    } else {
      setSelectedPost(e.state);
    }
  });


  const [ isShowContent, setIsShowContent ] = useState(false);
  const showContent = () => { setIsShowContent(!isShowContent); }

  const handleClickSide = (e) => {
    if (isShowContent){
      if(!e.target.closest(classes.sidebar)) {
        setIsShowContent(false);
        console.log(isShowContent);
      }
    }
  }

  const { width, height } = useWindowDimensions();

  const [isOpen1, setIsOpen1] = useState(false);
  const dropdownRef1 = useRef();

  const [isOpen2, setIsOpen2] = useState(false);
  const dropdownRef2 = useRef();

    useEffect(() => {
        document.addEventListener("mousedown", handleOutsideClick1);
        return () => document.removeEventListener("mousedown", handleOutsideClick1);
    }, []);

    useEffect(() => {
        document.addEventListener("mousedown", handleOutsideClick2);
        return () => document.removeEventListener("mousedown", handleOutsideClick2);
    }, []);

    const handleOutsideClick1 = (e) => {
        if (dropdownRef1.current && !dropdownRef1.current.contains(e.target)) {
            setIsOpen1(false);
        }
    };

    const handleOutsideClick2 = (e) => {
      if (dropdownRef2.current && !dropdownRef2.current.contains(e.target)) {
          setIsOpen2(false);
        }
    };

    const [items, setItems] = useState([]);
    const [value, setValue] = useState("");
    const [filteredItems, setFiltered] = useState([]);

      // クリック時のfunction
    const handlePageChange = (data) => {
      let page_number = data['selected']; // クリックした部分のページ数が{selected: 2}のような形で返ってくる
      setOffset(page_number*perPage); // offsetを変更し、表示開始するアイテムの番号を変更
      setCurrentPage(data['selected']);
    }
    const handleSearch = (e) => {
      setValue(e.target.value);
      setOffset(0);
      setCurrentPage(0);
    };
  
    useEffect(() => {
      setItems(posts)
    });
    
    useEffect(() => {
      const newItems = items.filter((item) => {
        return (
          authors[item.user].toUpperCase().indexOf(value.trim().toUpperCase()) !== -1 ||
          item.slug.toUpperCase().indexOf(value.trim().toUpperCase()) !== -1 ||
          item.country.toUpperCase().indexOf(value.trim().toUpperCase()) !== -1 ||
          item.university.toUpperCase().indexOf(value.trim().toUpperCase()) !== -1 ||
          item.title.toUpperCase().indexOf(value.trim().toUpperCase()) !== -1 ||
          item.major.toUpperCase().indexOf(value.trim().toUpperCase()) !== -1
        );
      });
      setFiltered(newItems);
    }, [value]);

  const [showInitial, setShowInitial] = useState(true)

  const postSwitch = (showInitial) => {
    if (showInitial) {
      setShowInitial(false)
      return 
    } 
    setShowInitial(false)
      return
  }

  const callTwo =(e, showInitial) => {
    postSwitch(showInitial);
    handleSearch(e);
  }

  const changeSearch = (word) => {
    setValue(word);
    setOffset(0);
    setCurrentPage(0);
    postSwitch(showInitial);
  }


  return (
    <React.Fragment>
      {/* HIDDEN CHAPTERS MENU*/}
      {isShowContent && 
        <div className={classes.sideBar} style={{height: `${height - 80}px`}}>
          <form className={classes.searchBar} action="">
            <TextField InputProps={{ style: {fontSize: '15px', color: 'white'}}} InputLabelProps={{ style: {fontSize: '15px', color: 'white', marginLeft: '12px'}}} label={t('searchbar_default')} onChange={callTwo}></TextField>
          </form>
          <div className={classes.all}>
            <button onClick={(evt) =>{
              {!showInitial &&
              changeSearch('')
              evt.stopPropagation()
            }}}>
              {t('show_all')}
            </button>
          </div>
          <div>
            <div className={classes.buttonUni}>{t('University')}</div>
            {universities.map((university, index) =>
                    <div key={index}>
                        <button onClick={(evt) =>{
                          changeSearch(university)
                          evt.stopPropagation()
                        }}>
                            {university}
                        </button>
                    </div>
                )}
          </div>
          <div>
            <div className={classes.buttonUni}>{t('Major')}</div>
            {majors.map((major, index ) =>
                    <div key={index}>
                        <button onClick={(evt) =>{
                          changeSearch(major.en)
                          evt.stopPropagation()
                        }}>
                          {major.en}{major.jp}
                        </button>
                    </div>
                )}
          </div>
        </div>}

      <div className={classes.blog} onClick={handleClickSide}>
        <h1>{t('blog_title')}</h1>
        {window.location.pathname=='/blog/' || selectedPost==''
          ? 
          <div className={classes.entry}>
            <h3>{t('blog_top_welcome')}</h3>
            <p>{t('blog_top_ex1')}
              <br/>{t('blog_top_ex2')}<br/>
            </p>
                
          </div>
          :
          <div className={classes.entry}>
            <span className={classes.round_btn} onClick={() => setSelectedPost('')}></span>
            <h3> {selectedPost.title}</h3>
            <h3>{t('Writer')}: {authors[selectedPost.user]}</h3>
            <h3>{t('University')}: {selectedPost.university}</h3>
            <h3>{t('Major')}: {selectedPost.major}</h3>
            <h3>{t('Updated_at')}: {selectedPost.created_at.slice(0,10)}</h3>
            <img className={classes.thumbnail} src={selectedPost.thumbnail}/>
            <div dangerouslySetInnerHTML={{__html: selectedPost.body}}/>
          </div>
        }

        <button className={classes.switch} onClick={() => setIsShowContent(!isShowContent)}>{t('searchbar')}</button>
        <div>
          {!filteredItems || showInitial
            ? posts.slice(offset, offset + perPage).map((item, index) => {
                if (item.publish) {return (
                  <div className={classes.post} key={item.id}>
                      <button onClick={itemClicked(item)}>
                          <img className={classes.image} src={item.thumbnail} alt='' /><br/>
                          <p className={classes.imgName}>{item.title} <br/> {authors[item.user]} <br/> {item.country} <br/> {'大学：'+item.university} <br/> {'専攻：'+item.major}  <br/> {item.summary} <br/> {'投稿日：'+ item.created_at.slice(0,10)} </p> 
                      </button>
                  </div>
                );
              }})
            : filteredItems.slice(offset, offset + perPage).map((item, index) => {
              if (item.publish) {return (
                  <div className={classes.post} key={item.id}>
                      <button onClick={itemClicked(item)}>
                          <img className={classes.image} src={item.thumbnail} alt='' /><br/>
                          <p className={classes.imgName}>{item.title} <br/> {authors[item.user]} <br/> {item.country} <br/> {'大学：'+item.university} <br/> {'専攻：'+item.major}  <br/> {item.summary} <br/> {'投稿日：'+ item.created_at.slice(0,10)} </p> 
                      </button>
                  </div>
                );
              }})}
        </div> 
        <ReactPaginate
            previousLabel={'←'}
            nextLabel={'→'}
            breakLabel={'...'}
            pageCount={Math.ceil(posts.length/perPage)} // 全部のページ数。端数の場合も考えて切り上げに。
            marginPagesDisplayed={2} // 一番最初と最後を基準にして、そこからいくつページ数を表示するか
            pageRangeDisplayed={5} // アクティブなページを基準にして、そこからいくつページ数を表示するか
            onPageChange={handlePageChange} // クリック時のfunction
            containerClassName={classes.pagination} // ページネーションであるulに着くクラス名
            subContainerClassName={'pages pagination'}
            forcePage={currentPage}
            activeClassName={classes.active} // アクティブなページのliに着くクラス名
            previousClassName={classes.pagination__previous} // 「<」のliに着けるクラス名
            nextClassName={classes.pagination__next} // 「>」のliに着けるクラス名
            disabledClassName={classes.pagination__disabled} // 使用不可の「<,>」に着くクラス名
          />
        <Contacts/>
      </div>
    </React.Fragment>
  );
}
export default Blog;