// const TOKEN = "something here"

export default class API {
  static loginUser(data) {
    var link;
    if (process.env.NODE_ENV === 'production') {
      link = 'https://enfty.herokuapp.com/auth/'
    } else {
      link = 'http://127.0.0.1:8000/auth/'
    }
    return fetch(link, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
    .then( res => res.json())
  }

  static signUpUser(data) {
    var link;
    if (process.env.NODE_ENV === 'production') {
      link = 'https://enfty.herokuapp.com/api/users/'
    } else {
      link = 'http://127.0.0.1:8000/api/users/'
    }
    return fetch(link, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
    .then( res => res.json())
  }

  static loginCreator(data) {
    var link;
    if (process.env.NODE_ENV === 'production') {
      link = 'https://enfty.herokuapp.com/auth/'
    } else {
      link = 'http://127.0.0.1:8000/auth/'
    }
    return fetch(link, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
    .then( res => res.json())
  }

  static compileCode(data) {
    // return fetch(`http://localhost:8000/api/compiler-api/`, {
      var link;
      if (process.env.NODE_ENV === 'production') {
        link = 'https://enfty.herokuapp.com/api/compiler-api/'
      } else {
        link = 'http://127.0.0.1:8000/api/compiler-api/'
      }
      return fetch(link, {
    // return fetch(`http://127.0.0.1:8000/api/compiler-api/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data)
    })
    .then( res => res.json())
  }

  // static getQuestion(data) {
  //   console.log(data.id, data.lang);
  //   return fetch(`http://127.0.0.1:8000/api/question-api/?id=`+data.id+`&lang=`+data.lang, {
  //     method: 'GET',
  //     headers: {
  //       'Content-Type': 'application/json',
  //     }
  //   })
  //   .then( res => res.json())
  // }

//   static loginUser(data) {
//     return fetch(`http://18.191.60.144:8000/auth/`, {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json'
//       },
//       body: JSON.stringify(data)
//     })
//     .then( res => res.json())
//   }

//   static signUpUser(data) {
//     return fetch(`http://18.191.60.144:8000/api/users/`, {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json'
//       },
//       body: JSON.stringify(data)
//     })
//     .then( res => res.json())
//   }

//   static compileCode(data) {
//     return fetch(`http://18.191.60.144:8000/api/compiler-api/`, {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify(data)
//     })
//     .then( res => res.json())
//   }
}
