import React, { useEffect, useState } from 'react';
import classes from './Navbar.module.scss';
import logo from '../../../asset/images/logo/002_enfty_logo_landscape_dark_transparent.png';
import DropdownMenu from './DropdownMenu/DropdownMenu';
import { Link } from 'react-router-dom';
// import { isMobile } from 'react-device-detect';
import { useTranslation　} from 'react-i18next';

const useNavbar = () => {
  const [t, i18n] = useTranslation();
  const [lang, setLang] = useState('jp');

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang, i18n]);
  
  function handleCLick() {
    setLang(lang === 'en' ? 'jp': 'en');
  }

  function refresh(){
    if (window.location.pathname != "/blog") {
      window.history.pushState(null, null, '/blog/');
      location.reload();
     }
  }

  return (
    <React.Fragment>
      <div className={classes.nav}>
        <div className={classes.logo}>
          <Link to="/"><img src={logo} alt="" width='130'/></Link>
        </div>
        {/* {!isMobile &&  */}
        <div className={classes.laptop}>
          <ul>
          <li><Link to="/">{t('home')}</Link></li>
          <li><Link to="/overview" >{t('overview')}</Link></li>
          {/* <li><Link to="/demo" >{t('demo')}</Link></li> */}
          <li><Link to="/blog" onClick={refresh}>{t('blog')}</Link></li>
          <li><Link to="/people" >{t('people')}</Link></li>
            {/*<li><Link to="/login" ><div className={classes.login} >{t('launch')}</div></Link></li>*/}
          <li><button onClick={handleCLick}>{lang === 'en' ? 'JP' : 'EN' }</button></li>
        </ul>
        </div>
        {/* } */}
        {/* {isMobile &&  */}
        <div className={classes.mobile}>
          <button onClick={handleCLick}>{lang === 'en' ? 'JP' : 'EN' }</button>
          <div className={classes.menu}><DropdownMenu /></div>
        </div>
        {/* } */}
      </div>
    </React.Fragment>
  );
}

export default useNavbar;