import React from 'react';
import classes from './Creatorpage.module.scss';
import CreatorTabs from './CreatorTabs/CreatorTabs';
import CreateProblem from './CreatorTabs/CreateProblem/CreateProblem';
import EditProblem from './CreatorTabs/EditProblem/EditProblem';
import LogoutCreator from './CreatorTabs/LogoutCreator/LogoutCreator';
import { Route } from 'react-router-dom';

const Creatorpage = () => {
  
  return (
  <div className={classes.creatorpage}>
    <Route path="/creatorpage/create" exact component={CreateProblem} />
    <Route path="/creatorpage/edit" component={EditProblem} />
    <Route path="/creatorpage/leave" component={LogoutCreator} />
    <React.Fragment>
      <CreatorTabs/>
    </React.Fragment>
  </div>
  )
}

export default Creatorpage;