import React, { useEffect, useState } from 'react';
import classes from './People.module.scss';
import yasu from '../../../asset/images/profiles/yasu2.png';
import tom from '../../../asset/images/profiles/tom.png';
import jason from '../../../asset/images/profiles/jason2.png';
import yoshi from '../../../asset/images/profiles/yoshi.png';
import nori from '../../../asset/images/profiles/nori.png';
import luka from '../../../asset/images/profiles/luka.png';
import yuto from '../../../asset/images/profiles/yuto.png';
import arata from '../../../asset/images/profiles/arata.png';
import jumpei from '../../../asset/images/profiles/jumpei.png';
import soon from '../../../asset/images/profiles/coming_soon.png';
import rio from '../../../asset/images/profiles/coming_soon.png';
import hami from '../../../asset/images/profiles/hami.jpg'
import takeshi from '../../../asset/images/profiles/takeshi.jpg'
// import BreakCreator from '../../../utils/BreakCreator/BreakCreator';
import PeopleModal from './PeopleModal/PeopleModal';
import { useTranslation } from 'react-i18next';

const People = () => {
  const [t, i18n] = useTranslation();
  const [lang] = useState();

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang, i18n]);

  // backend data
  const pngfiles = [yasu, tom, yoshi, nori, luka];
  const pngfiles2 = [nori, luka, hami, takeshi, rio];
  
  const names = [
    <React.Fragment>{t('names1')}</React.Fragment>,
    <React.Fragment>{t('names2')}</React.Fragment>,
    // <React.Fragment>{t('names3')}</React.Fragment>,
    <React.Fragment>{t('names4')}</React.Fragment>,
    <React.Fragment>{t('names5')}</React.Fragment>,
    <React.Fragment>{t('names6')}</React.Fragment>,
    // <React.Fragment>{t('names7')}</React.Fragment>,
    // <React.Fragment>{t('names8')}</React.Fragment>,
    // <React.Fragment>{t('names9')}</React.Fragment>,
    // <React.Fragment>{t('names10')}</React.Fragment>,
    // <React.Fragment>{t('names11')}</React.Fragment>
  ];

  const roles = [
    <React.Fragment>{t('roles1')}</React.Fragment>,
    <React.Fragment>{t('roles2')}</React.Fragment>,
    // <React.Fragment>{t('roles3')}</React.Fragment>,
    <React.Fragment>{t('roles4')}</React.Fragment>,
    <React.Fragment>{t('roles5')}</React.Fragment>,
    <React.Fragment>{t('roles6')}</React.Fragment>,
    // <React.Fragment>{t('roles7')}</React.Fragment>,
    // <React.Fragment>{t('roles8')}</React.Fragment>,
    // <React.Fragment>{t('roles9')}</React.Fragment>,
    // <React.Fragment>{t('roles10')}</React.Fragment>,
    // <React.Fragment>{t('roles11')}</React.Fragment>
  ];

  const motto = [
    <React.Fragment>{t('motto1')}</React.Fragment>,
    <React.Fragment>{t('motto2')}</React.Fragment>,
    // <React.Fragment>{t('motto3')}</React.Fragment>,
    <React.Fragment>{t('motto4')}</React.Fragment>,
    <React.Fragment>{t('motto5')}</React.Fragment>,
    <React.Fragment>{t('motto6')}</React.Fragment>,
    // <React.Fragment>{t('motto7')}</React.Fragment>,
    // <React.Fragment>{t('motto8')}</React.Fragment>,
    // <React.Fragment>{t('motto9')}</React.Fragment>,
    // <React.Fragment>{t('motto10')}</React.Fragment>,
    // <React.Fragment>{t('motto11')}</React.Fragment>,
  ];

  const university = [
    <React.Fragment>{t('university1')}</React.Fragment>,
    <React.Fragment>{t('university2')}</React.Fragment>,
    // <React.Fragment>{t('university3')}</React.Fragment>,
    <React.Fragment>{t('university4')}</React.Fragment>,
    <React.Fragment>{t('university5')}</React.Fragment>,
    <React.Fragment>{t('university6')}</React.Fragment>,
    // <React.Fragment>{t('university7')}</React.Fragment>,
    // <React.Fragment>{t('university8')}</React.Fragment>,
    // <React.Fragment>{t('university9')}</React.Fragment>,
    // <React.Fragment>{t('university10')}</React.Fragment>,
    // <React.Fragment>{t('university11')}</React.Fragment>,
  ];

  const major = [
    <React.Fragment>{t('major1')}</React.Fragment>,
    <React.Fragment>{t('major2')}</React.Fragment>,
    // <React.Fragment>{t('major3')}</React.Fragment>,
    <React.Fragment>{t('major4')}</React.Fragment>,
    <React.Fragment>{t('major5')}</React.Fragment>,
    <React.Fragment>{t('major6')}</React.Fragment>,
    // <React.Fragment>{t('major7')}</React.Fragment>,
    // <React.Fragment>{t('major8')}</React.Fragment>,
    // <React.Fragment>{t('major9')}</React.Fragment>,
    // <React.Fragment>{t('major10')}</React.Fragment>,
    // <React.Fragment>{t('major11')}</React.Fragment>,
  ];

  const area = [
    <React.Fragment>{t('area1')}</React.Fragment>,
    <React.Fragment>{t('area2')}</React.Fragment>,
    // <React.Fragment>{t('area3')}</React.Fragment>,
    <React.Fragment>{t('area4')}</React.Fragment>,
    <React.Fragment>{t('area5')}</React.Fragment>,
    <React.Fragment>{t('area6')}</React.Fragment>,
    // <React.Fragment>{t('area7')}</React.Fragment>,
    // <React.Fragment>{t('area8')}</React.Fragment>,
    // <React.Fragment>{t('area9')}</React.Fragment>,
    // <React.Fragment>{t('area10')}</React.Fragment>,
    // <React.Fragment>{t('area11')}</React.Fragment>,
  ];

  const introduction = [
    <React.Fragment>{t('intro1')}</React.Fragment>,
    <React.Fragment>{t('intro2')}</React.Fragment>,
    // <React.Fragment>{t('intro3')}</React.Fragment>,
    <React.Fragment>{t('intro4')}</React.Fragment>,
    <React.Fragment>{t('intro5')}</React.Fragment>,
    <React.Fragment>{t('intro6')}</React.Fragment>,
    // <React.Fragment>{t('intro7')}</React.Fragment>,
    // <React.Fragment>{t('intro8')}</React.Fragment>,
    // <React.Fragment>{t('intro9')}</React.Fragment>,
    // <React.Fragment>{t('intro10')}</React.Fragment>,
    // <React.Fragment>{t('intro11')}</React.Fragment>,
  ];


  const names2 = [
    <React.Fragment>{t('names5')}</React.Fragment>,
    <React.Fragment>{t('names6')}</React.Fragment>,
    <React.Fragment>{t('names12')}</React.Fragment>,
    <React.Fragment>{t('names13')}</React.Fragment>,
    <React.Fragment>{t('names14')}</React.Fragment>,
  ];

  const roles2 = [
    <React.Fragment>{t('roles5')}</React.Fragment>,
    <React.Fragment>{t('roles6')}</React.Fragment>,
    <React.Fragment>{t('roles12')}</React.Fragment>,
    <React.Fragment>{t('roles13')}</React.Fragment>,
    <React.Fragment>{t('roles14')}</React.Fragment>,
  ];

  const motto2 = [
    <React.Fragment>{t('motto5')}</React.Fragment>,
    <React.Fragment>{t('motto6')}</React.Fragment>,
    <React.Fragment>{t('motto12')}</React.Fragment>,
    <React.Fragment>{t('motto13')}</React.Fragment>,
    <React.Fragment>{t('motto14')}</React.Fragment>,
  ];

  const university2 = [
    <React.Fragment>{t('university5')}</React.Fragment>,
    <React.Fragment>{t('university6')}</React.Fragment>,
    <React.Fragment>{t('university12')}</React.Fragment>,
    <React.Fragment>{t('university13')}</React.Fragment>,
    <React.Fragment>{t('university14')}</React.Fragment>,
  ];

  const major2 = [
    <React.Fragment>{t('major5')}</React.Fragment>,
    <React.Fragment>{t('major6')}</React.Fragment>,
    <React.Fragment>{t('major12')}</React.Fragment>,
    <React.Fragment>{t('major13')}</React.Fragment>,
    <React.Fragment>{t('major14')}</React.Fragment>,
  ];

  const area2 = [
    <React.Fragment>{t('area5')}</React.Fragment>,
    <React.Fragment>{t('area6')}</React.Fragment>,
    <React.Fragment>{t('area12')}</React.Fragment>,
    <React.Fragment>{t('area13')}</React.Fragment>,
    <React.Fragment>{t('area14')}</React.Fragment>,
  ];

  const introduction2 = [
    <React.Fragment>{t('intro5')}</React.Fragment>,
    <React.Fragment>{t('intro6')}</React.Fragment>,
    <React.Fragment>{t('intro12')}</React.Fragment>,
    <React.Fragment>{t('intro13')}</React.Fragment>,
    <React.Fragment>{t('intro14')}</React.Fragment>,
  ];

  // Define Modal Settings
  const [show, setShow] = useState(false);
  const [memberIndex, setMemberIndex] = useState(-1);
  const hideModal = () => {
    setShow(false);
    setMemberIndex(-1);
  }
  const showModal = (index) => {
    setShow(true);
    setMemberIndex(index);
  }

  const [show2, setShow2] = useState(false);
  const [memberIndex2, setMemberIndex2] = useState(-1);
  const hideModal2 = () => {
    setShow2(false);
    setMemberIndex2(-1);
  }
  const showModal2 = (index) => {
    setShow2(true);
    setMemberIndex2(index);
  }

  return (
    <div className={classes.people}>
      {/* <h1><span style={{color: '#ff2672'}}>T</span>eam</h1> */}
      <h2>{t('Founders')}</h2>
      <PeopleModal 
        show          = {show} 
        handleClose   = {hideModal}
        pics          = {pngfiles[memberIndex]}
        names         = {names[memberIndex]}
        roles         = {roles[memberIndex]}
        motto         = {motto[memberIndex]} 
        university    = {university[memberIndex]}
        major         = {major[memberIndex]} 
        area          = {area[memberIndex]}
        introduction  = {introduction[memberIndex]}/>
      {[...Array(names.length).keys()].map((i) => {
        return (
        <React.Fragment key={i} >
          <div className={classes.profile}>
            <button onClick={() => showModal(i)}>
                <img className={classes.image} src={pngfiles[i]} alt=''/>
                <p className={classes.imgName}>{names[i]}<br/><span style={{fontSize:'10px'}}>{roles[i]}</span></p>
            </button>
          </div>
        </React.Fragment>);
      })}

      {/* <h1><span style={{color: '#ff2672'}}>T</span>eam</h1> */}
      <h2>{t('Team')}</h2>
      <PeopleModal 
        show          = {show2} 
        handleClose   = {hideModal2}
        pics          = {pngfiles2[memberIndex2]}
        names         = {names2[memberIndex2]}
        roles         = {roles2[memberIndex2]}
        motto         = {motto2[memberIndex2]} 
        university    = {university2[memberIndex2]}
        major         = {major2[memberIndex2]} 
        area          = {area2[memberIndex2]}
        introduction  = {introduction2[memberIndex2]}/>
      {[...Array(names2.length).keys()].map((j) => {
        return (
        <React.Fragment key={j} >
          <div className={classes.profile}>
            <button onClick={() => showModal2(j)}>
                <img className={classes.image} src={pngfiles2[j]} alt=''/>
                <p className={classes.imgName}>{names2[j]}<br/><span style={{fontSize:'10px'}}>{roles2[j]}</span></p>
            </button>
          </div>
        </React.Fragment>);
      })}

    </div>
  );
}

export default People;