import React from 'react';
import classes from './Homepage.module.scss';
import ScrollToTop from '../../utils/ScrollToTop/ScrollToTop';
import Navbar from './Navbar/Navbar';
import HomeContents from './HomeContents/HomeContents';
import Overview from './Overview/Overview';
// import Demo from './Demo/Demo';
import Blog from './Blog/Blog';
import People from './People/People';
import LoginPage from './LoginPage/LoginPage';
import NotFound from './NotFound/NotFound';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import enJson from '../../asset/locales/en.json';
import jpJson from '../../asset/locales/jp.json';

i18n.use(initReactI18next).init({
  debug: false,
  resources: {
    en: { translation: enJson },
    jp: { translation: jpJson }
  },
  lng: 'jp',
  fallbackLng: false,
  returnEmptyString: false,
});

const homepage = () => {
  return (
    <div className={classes.homepage}>
      <Router>
        <ScrollToTop />
        <Navbar />
        <Switch>
          <Route path="/" exact component={HomeContents} />
          <Route path="/overview" component={Overview} />
          {/* <Route path="/demo" component={Demo} /> */}
          <Route path="/blog" component={Blog} />
          <Route path="/people"component={People} />
          <Route path="/login" component={LoginPage} />
          <Route component={NotFound} />
        </Switch>
      </Router>
    </div>
  )
}

export default homepage;