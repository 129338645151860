import React from 'react';
import classes from './UserCalendar.module.scss';
import Calendar from 'react-calendar';
// import 'react-calendar/dist/Calendar.css';

const UserCalendar = () => {
  return (
  <div className={classes.UserCalendar}>
    <Calendar />
  </div>
  )
}

export default UserCalendar;
