import React from 'react';
import classes from './RankingModal.module.scss';

const RankingModal = (props) => {
  
  const topNames = ['yasu_rules', 'ikomoto829', 'jjjcastle', 'ykita0224', 'norimizu', 'yutotheboy', 'taiwan_gf', 'peter_aws', 'ann_django', 'hanaka_bvr'];
  
  const showHideClassName  = props.show ? classes.show : classes.hide;
  return (
    <React.Fragment>
      <div className={showHideClassName}>
        <div className={classes.modal} onClick={props.handleClose ? props.handleClose : props.show}>
          {/* <section className={classes.box} onClick={props.show ? props.show : undefined}> */}
          <section className={classes.box} onClick={props.handleClose ? props.handleClose : props.show}>
            <br/>
            <h1>Leaderboard</h1>
            <table>
              <tbody>
                {[...Array(topNames.length).keys()].map((i) => {
                  return (
                  <React.Fragment key={i}>
                    <tr><th>{i+1}</th><td> {topNames[i]}</td></tr>
                  </React.Fragment>);
                })}
              </tbody>
            </table>
            {/* <button className={classes.closeButton} onClick={props.handleClose}>&times;</button> */}
          </section>
        </div>
      </div>
    </React.Fragment>
  )
}

export default RankingModal;