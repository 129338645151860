import React from 'react';
import classes from './MyAccountPage.module.scss';
import { Link } from 'react-router-dom';
import BreakCreator from '../../../../utils/BreakCreator/BreakCreator';
import icon from '../../../../asset/images/logo/enfty_user_icon_circle.png';

const MyAccount = () => {
  var avatar = icon
  var name = 'Babe Ikomot';
  var username = 'muscle123';
  var email = 'codifier456@gmail.com';
  var birth = '29 Aug 1997';
  var gender = 'Sapiosexual';
  var about = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
  // backend data

  
  
  return (
    <div className={classes.myAccount}>
      <table>
        <tbody>
          <tr><th><h1>My Account</h1></th><td><Link to="/userpage/myAccountEdit"><button className={classes.edit}>Edit</button></Link></td></tr>
        </tbody>
        {/* <BreakCreator num={1}/> */}
        <tbody>
          <tr><th>Avatar: </th><td><img src={avatar} alt='' width='50'/></td></tr>
          <tr><th>Name: </th><td>{ name }</td></tr>
          <tr><th>Username: </th><td>{ username }</td></tr>
          <tr><th>Email address: </th><td>{ email }</td></tr>
          <tr><th>Date of Birth: </th><td>{ birth }</td></tr>
          <tr><th>Gender: </th><td>{ gender }</td></tr>
        </tbody>
      </table>
      <BreakCreator num={1}/>
      <h3>About me: </h3>
      <p>{ about }</p>
    </div>
  );
}

export default MyAccount;