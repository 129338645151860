import React from 'react';
import LoginContents from './LoginContents/LoginContents';
// import BreakCreator from '../../../utils/BreakCreator/BreakCreator';
import classes from './LoginPage.module.scss';
import {Route} from 'react-router-dom';
import SignUp from './SignUp/SignUp';


const LoginPage = () => {
  return (
    <div className={classes.LoginPage}>
      <div className={classes.login}>
        <Route path="/login" exact component={LoginContents} />
        <Route path="/login/new_account" component={SignUp} />
      </div>
    </div>
  );
}

export default LoginPage;