import React, { useEffect, useState } from 'react';
import classes from './SubmitModal.module.scss';
import { useTranslation　} from 'react-i18next';

const SubmitModal = (props) => {
  const [t, i18n] = useTranslation();
  const [lang] = useState();
  
  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang, i18n]);
  
  const showHideClassName  = props.show ? classes.show : classes.hide;
  return (
    <React.Fragment>
      <div className={showHideClassName}>
        <div className={classes.modal} onClick={props.handleClose ? props.handleClose : props.show}>
          <section className={classes.box} onClick={props.handleClose ? props.handleClose : props.show}>
            <h1>Question #{props.questionId} - {props.questionName}</h1>
            <p>{t('submitModal')}</p>
          </section>
        </div>
      </div>
    </React.Fragment>
  )
}

export default SubmitModal;