import React, {useState, useEffect} from 'react';
import classes from './SettingsPage.module.scss';
import BreakCreator from '../../../../utils/BreakCreator/BreakCreator';
import { useTranslation　} from 'react-i18next';

const settings = () => {
  const [t, i18n] = useTranslation();
  const [lang, setLang] = useState('jp');

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang, i18n]);

  function handleCLick() {
    setLang(lang === 'en' ? 'jp': 'en');
  }
  return (
    <div className={classes.settings}>
      <h1>Settings</h1>
      <BreakCreator num={1}/>
      <table>
        <tbody>
          <tr><th>Password: </th><td><button className={classes.change}>Change</button></td></tr>
          <tr><th>Wallpaper: </th><td><button className={classes.change}>Change</button></td></tr>
          <tr><th>Language: </th><td><button onClick={handleCLick}>{lang === 'en' ? 'JP' : 'EN' }</button></td></tr>
        </tbody>
      </table>
    </div>
  );
}

export default settings;