import React, { Component } from 'react';
import classes from './LogoutPage.module.scss';
import BreakCreator from '../../../../utils/BreakCreator/BreakCreator';
import icon from '../../../../asset/images/IMG_0100.JPG';
import { useCookies, withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';
import { BiSleepy } from 'react-icons/bi';

class Logout extends Component {

  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };
  
  logoutUser = () => {
    const { cookies } = this.props;
    cookies.remove('user-token', { path: '/' });
    cookies.remove('inCourse-token', { path: '/' });
    window.location.href = '/';
    return false;
  }

  render () {
    return (
      <div className={classes.logout}>
        <h1>Log Out</h1>
        <BreakCreator num={1}/>
        <button className={classes.logout} onClick={this.logoutUser}><img src={icon} alt='' width='50' /></button>
      </div>
    );
  }
}
export default withCookies(Logout);
// export default Logout;


// const Logout = () => {
//   const cookies = new Cookies();
  
//   const [token, setToken, deleteToken ] = useCookies(['user-token'])
  
//   // useEffect(() => {
//   //   if(!token['user-token']) window.location.href = '/';
//   // },[token])

//   const logoutUser = () => {
//     console.log("hello world yoshi yoshi yoshi");
//     // setToken('user-token', "undefined");
//     // setToken();
//     deleteToken(['user-token']);
//     // cookies.remove('user-token');
//     window.location.href = '/';
//     console.log("world");
//   }

//   return (
//     <div className={classes.logout}>
//       <h1>Log Out</h1>
//       <BreakCreator num={1}/>
//       <button className={classes.logout} onClick={logoutUser}><img src={icon} alt='' width='50' /></button>
//     </div>
//   );
// }
// export default withCookies(Logout);
// export default Logout;