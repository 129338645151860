import React, { useEffect, useState } from 'react';
import classes from './CreatorTabs.module.scss';
import { Link } from 'react-router-dom';
import { useTranslation　} from 'react-i18next';
import logo from '../../../asset/images/logo/007_enfty_logo_only_dark_transparent.png';

const CreatorTabs = () => {
  const [t, i18n] = useTranslation();
  const [lang] = useState();
  
  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang, i18n]);

  const [activateButton, setActivateButton] = useState([true, false, false]);
  var style = { backgroundColor: "#FF2672", border: 'none' }
  return (
    <div className={classes.usertabs}>
        <div className={classes.tabs}>
          <img src={logo} alt='' width='150'/>
          <Link to="/creatorpage/create"><button style={activateButton[0] ? style : null} onClick={() => setActivateButton([true, false, false])}>{t('create')}</button></Link>
          <Link to="/creatorpage/edit"><button style={activateButton[1] ? style : null} onClick={() => setActivateButton([false, true, false])}>{t('edit')}</button></Link>
          <Link to="/creatorpage/leave"><button style={activateButton[2] ? style : null} onClick={() => setActivateButton([false, false, true])}>{t('leave')}</button></Link>
        </div>
    </div>
  );
}

export default CreatorTabs;