import React, { useEffect, useState } from 'react';
import Contacts from './Contacts/Contacts';
import classes from './HomeContents.module.scss';
import { useTranslation　} from 'react-i18next';
import MovingText from 'react-moving-text';
// import TextAnimation from 'react-animate-text';

const HomeContents = () => {
  var index;
  let letter = 'enfty inc.';
  const [t, i18n] = useTranslation();
  const [lang] = useState();

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang, i18n]);

  return(
    <div>
      <div className={classes.header}>
        <div className={classes.title_subtitle}>
          {/* <h2><span style={{color: '#ff2672'}}>e</span>nfty inc.</h2> */}
          <MovingText
            type="fadeIn"
            duration="1000ms"
            delay="0"
            direction="normal"
            timing="ease-in-out"
            iteration="1"
            fillMode="none">
            <h2><span style={{color: '#ff2672'}}>e</span>nfty inc.</h2>
            <h5>{t('subtitle')}</h5>
          </MovingText>
          {/* <h5>{t('subtitle')}</h5> */}
          {/* <TextAnimation>
            <h5>{t('subtitle')}</h5>
          </TextAnimation> */}
        </div>
      </div>
      <div className={classes.philosophy}>
        <div className={classes.title_subtitle}>
          <h2>{t('philosophy')}</h2>
        </div>
        <p>
          {t('philosophy_text')}
          {/* At <span style={{color: '#ff2672'}}>e</span>nfty, we aim to change what is meant by <strong>“Engineering”</strong> in Japan, and from there, enhance the interactions of the community with space, engineering and programming. */}
        </p>
      </div>
      {/* <div className={classes.movie}>
        <h2>{t('movie')}</h2>
        <iframe title="intro" src="https://www.youtube.com/embed/TUKA7ToC8R0?rel=0;modestbranding=1" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"></iframe>
      </div> */}
      <Contacts/>
    </div>
  ); 
}


export default HomeContents;