import React from 'react';
import classes from './NotFound.module.scss';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return(
    <div className={classes.notFound}>
      <h1>404</h1>
      <h2>"The input is invalid. Please debug the web url and get back to us!"</h2>
      <Link to="/"><button>Return Home</button></Link>
    </div>
  );
}

export default NotFound;