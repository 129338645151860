import React, {useState, useEffect} from 'react';
import classes from './MyCoursesPage.module.scss';
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { easeQuadInOut } from 'd3-ease';
import AnimatedProgressProvider from "./AnimatedProgressProvider/AnimatedProgressProvider";
import Modal from './Modal/Modal';
import { connect } from 'react-redux'
import { CppCourse , PythonCourse} from '../../../../redux/CourseAction';
import { useCookies } from 'react-cookie';
// import { SiTeradata } from 'react-icons/si';

// const MyCourses = (props) => {
const MyCourses = () => {

  const percentage  = [66, 20, 90, 70, 80, 15, 35, 69, 29, 37, 47, 84, 18, 66, 20, 90, 70, 80];
  
  const [show, setShow] = useState(false);
  const [courseIndex, setCourseIndex] = useState(-1);
  const [token, setToken] = useCookies(['inCourse-token'])
  const [courseName, setCourseName] = useState([]);
  const [description, setDescription] = useState([]);
  const [barColor, setBarColor] = useState([]);
  // Course Colors
  // Green:       #3CBD09 - Trivia
  // Dark Green:  #257306 - BIO
  // Blue:        #4E50FF - BPC
  // Light Blue:  #0796E8 - PHY
  // Orange:      #E84E17 - GEO
  // Purple:      #A317E8 - UNI
  // Yellow:      #FFA719 - CHEM
  // Red:         #FF0A56 - MATH

  const hideModal = () => {
    setShow(false);
    setCourseIndex(-1);
  }
  
  const showModal = (index) => {
    setShow(true);
    setCourseIndex(index);
    setToken('inCourse-token', index+1);
  }
  
  useEffect(() => {
    var link;
    // console.log(process.env.NODE_ENV)
    if (process.env.NODE_ENV === 'production') {
      link = 'https://enfty.herokuapp.com/api/courses/'
    } else {
      link = 'http://127.0.0.1:8000/api/courses/'
    }
    fetch(link, {
    // fetch('http://127.0.0.1:8000/api/courses/', {
    // fetch('https://enfty.herokuapp.com/api/courses/', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(response => response.json())
    .then(data => {
      const courseNameTemp = [];
      const descriptionTemp = [];
      const barColorTemp = [];
      for (let i=0; i < data.length; i++) {
        courseNameTemp.push(data[i].name)
        descriptionTemp.push(data[i].description)
        barColorTemp.push(data[i].color)
      }
      setCourseName(courseNameTemp);
      setDescription(descriptionTemp);
      setBarColor(barColorTemp);
    })
    .catch((error) => {
      console.error('Error:', error);
    });
  }, [])

	return (
		<div className={classes.myCourses}>
      <Modal show={show} handleClose={hideModal} courseName={courseName[courseIndex]} description={description[courseIndex]}/>
			{[...Array(courseName.length).keys()].map((i) => {
        return (
          <React.Fragment key={i} >
            <div className={classes.courseBar} >
              <button onClick={() => showModal(i)}>
              <AnimatedProgressProvider valueStart={0} valueEnd={percentage[i]} duration={0.3} easingFunction={ easeQuadInOut } >
              {value => {
                return (
                <CircularProgressbar
                  value={value} text={courseName[i]} background backgroundPadding={5} 
                  styles={buildStyles({
                  pathColor: 　						 barColor[i],
                  textColor: 							'#000',
                  backgroundColor: 				'#fff',
                  trailColor: 						'#fff',
                  strokeLinecap: 					'butt',
                  textSize: 							'12px',
                  pathTransitionDuration: 'none'
                })}/>
                );}}
              </AnimatedProgressProvider>
              </button>
            </div>
          </React.Fragment>
        );
      })}
		</div>
  );
}

export default MyCourses;

// const mapStateToProps = state => {
//   return {
//     courseName: state.courseName
//   }
// }

// const mapDispatchToProps = dispatch => {
//   return {
//     CppCourse: () => dispatch(CppCourse()),
//     PythonCourse: () => dispatch(PythonCourse())
//   }
// }

// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(MyCourses)