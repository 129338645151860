import React, {useState, useEffect} from 'react';
import classes from './UserTabs.module.scss';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import logo from '../../../asset/images/logo/007_enfty_logo_only_dark_transparent.png';
import icon from '../../../asset/images/logo/enfty_user_icon_circle.png';
import { useTranslation　} from 'react-i18next';

const useUserTabs = () => {
  const [t, i18n] = useTranslation();
  const [lang] = useState();

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang, i18n]);

  const [activateButton, setActivateButton] = useState([false, false, false]);
  var style = { backgroundColor: "#FF2672", border: 'none' }
  return (
    <div className={classes.usertabs}>
      {/* Left Side */}
      <div className={classNames(classes.split, classes.left)}>
        <div className={classes.tabs}>
          <img src={logo} alt='' width='150'/>
          <Link to="/userpage/myCourses"><button style={activateButton[0] ? style : null} onClick={() => setActivateButton([true,false,false])}>{t('mycourses')}</button></Link>
          <Link to="/userpage/myCommunities"><button style={activateButton[1] ? style : null} onClick={() => setActivateButton([false, true, false])}>{t('mycommunities')}</button></Link>
          <Link to="/userpage/Categories" ><button style={activateButton[2] ? style : null} onClick={() => setActivateButton([false,false,true])}>{t('categories')}</button></Link>
        </div>
      </div>
      {/* Right Side */}
      <div className={classNames(classes.split, classes.right)}>
        <input type="text" placeholder="Search.." />
        <div className={classes.dropdown} onClick={() => setActivateButton([false,false,false])}>
          <Link to="/userpage/myAccount"><button className={classes.dropbtn}><img src={icon} alt='' width='50'/></button></Link>
          <div className={classes.dropdownContent}>
            <Link to="/userpage/myAccount">{t('myaccount')}</Link> 
            <Link to="/userpage/myPoints">{t('mypoints')}</Link>
            <Link to="/userpage/settings">{t('setting')}</Link>
            <Link to="/userpage/creatorlogin">{t('creator_login')}</Link>
            <Link to="/userpage/logout">{t('log_out')}</Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default useUserTabs;