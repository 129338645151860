import React, {useState, useEffect} from 'react';
import classes from './UserRanking.module.scss';
import RankingModal from './RankingModal/RankingModal';

const UserRanking = () => {
  const ranking = [8, 11, 1, 6, 3, 8, 11, 1, 6, 3, 8, 11, 1, 6, 3, 8, 11, 1, 6, 3];
  const cohorts = [23, 15, 20, 24, 24, 23, 15, 20, 24, 24, 23, 15, 20, 24, 24, 23, 15, 20, 24, 24];
  // const courseColor = ['#3CBD09', '#4E50FF', '#E84E17', '#A317E8', '#FFA719']

  const [show, setShow] = useState(false);
  const [courseIndex, setCourseIndex] = useState(-1);
  const [courseName, setCourseName] = useState([]);

  const hideModal = () => {
    setShow(false);
    setCourseIndex(-1);
  }

  const showModal = (index) => {
    setShow(true);
    setCourseIndex(index);
  }
  
  useEffect(() => {
    var link;
    if (process.env.NODE_ENV === 'production') {
      link = 'https://enfty.herokuapp.com/api/courses/'
    } else {
      link = 'http://127.0.0.1:8000/api/courses/'
    }
    fetch(link, {
    // fetch('http://127.0.0.1:8000/api/courses/', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(response => response.json())
    .then(data => {
      const courseNameTemp = [];
      for ( let i=0; i < data.length; i++) {
        courseNameTemp.push(data[i].name)
      }
      setCourseName(courseNameTemp);
    })
    .catch((error) => {
      console.error('Error:', error);
    });
  }, [])

  return (
  <div className={classes.UserRanking}>
    <h3>Rankings</h3>
    <RankingModal show={show} handleClose={hideModal} name={courseName[courseIndex]}/>
    <table>
      <tbody>
        {[...Array(courseName.length).keys()].map((i) => {
          return (
          <React.Fragment key={courseName[i]}>
            <tr><th><button onClick={() => showModal(i)}>{courseName[i]}</button></th><td> {ranking[i]} / {cohorts[i]}</td></tr>
          </React.Fragment>);
        })}
      </tbody>
    </table>
  </div>
  )
}

export default UserRanking;