import React, { useEffect, useState } from 'react';
import BreakCreator from '../../../../utils/BreakCreator/BreakCreator';
import classes from './Contacts.module.scss';
import { useTranslation　} from 'react-i18next';
import { FaInstagram, FaFacebook, FaBlog, FaLinkedin, FaYoutube, FaTwitter } from 'react-icons/fa';
import { SiGmail, SiTiktok } from 'react-icons/si'

const Contacts = () => {

  const getYear = () => {
    return new Date().getFullYear();
  }

  const [t, i18n] = useTranslation();
  const [lang] = useState();
  
  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang, i18n]);

  return (
    <div className={classes.main_footer}>
      <div className={classes.box}>
        <a className={classes.icon} style={{color:'#D44638'}} href="mailto:enfty.inc@gmail.com"><SiGmail size={32} /></a>
        <a className={classes.icon} style={{color:'#cd486b'}} href={t('insta_link')} rel="noopener noreferrer" target='_blank'><FaInstagram size={32}/></a>
        {/* <a className={classes.icon} style={{color:'#E32D54'}} href="https://youtube.com/channel/UCMf4vHbDEN1DrdCdEnRDsAw" rel="noopener noreferrer" target='_blank'><FaYoutube size={32}/></a> */}
        <a className={classes.icon} style={{color:'#4267B2'}} href="https://www.facebook.com/Enfty-inc-100326008421040/" rel="noopener noreferrer" target='_blank'><FaFacebook size={32}/></a>
        <a className={classes.icon} style={{color:'#0e76a8'}} href="https://uk.linkedin.com/company/enfty-inc?trk=public_profile_topcard-current-company" rel="noopener noreferrer" target='_blank'><FaLinkedin size={32}/></a>
        {/* <a className={classes.icon} style={{color:'#21759b'}} href="https://enftyblog.com/" rel="noopener noreferrer" target='_blank'><FaBlog size={32}/></a> */}
        <a className={classes.icon} style={{color:'#1DA1F2'}} href="https://www.twitter.com/" rel="noopener noreferrer" target='_blank'><FaTwitter size={32}/></a>
        {/* <a className={classes.icon} style={{color:'#69C9D0'}} href="https://www.tiktok.com/ja/" rel="noopener noreferrer" target='_blank'><SiTiktok size={32}/></a> */}
        <BreakCreator num={3} />
        <span style={{color: '#ff2672'}}>e</span>nfty inc. &copy; {getYear()}
      </div>
    </div>
  );
}

export default Contacts;