import React from 'react';
import classes from './CategoriesPage.module.scss'

const Categories = () => {
  return (
    <div className={classes.Categories}>
      <h1>Coming soon!</h1>
      <p>You can find out new courses and communities here.</p>
    </div>
  ) 
}

export default Categories;