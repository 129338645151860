import React from 'react';
import classes from './PeopleModal.module.scss';
import { useEffect, useState } from 'react';
import { useTranslation　} from 'react-i18next';


const PeopleModal = (props) => {
  const [t, i18n] = useTranslation();
  const [lang] = useState();

  const showHideClassName  = props.show ? classes.show : classes.hide;
  return (
    <React.Fragment>
      <div className={showHideClassName}>
        <div className={classes.modal} onClick={props.handleClose ? props.handleClose : props.show}>
          <section className={classes.box} onClick={props.handleClose ? props.handleClose : props.show}>
            <div className={classes.block}>
              <img className={classes.image} src={props.pics} alt='' width='200'/>
            </div>
            <div className={classes.block}>
              <h5>
                {props.names} <span>{props.roles}</span><br/>
                <span>{props.university}</span>
                <span> </span>
                <span>{props.major}</span><br/>
                <span>{t('area')} {props.area}</span>
              </h5>
              <h6>{props.motto}</h6>
              <p>{props.introduction}</p>
            </div>
          </section>
        </div>
      </div>
    </React.Fragment>
  )
}

export default PeopleModal;