import React, { useEffect, useState } from 'react';
import classes from './Overview.module.scss';
import BreakCreator from '../../../utils/BreakCreator/BreakCreator';
import { useTranslation　} from 'react-i18next';
// import ScrollToTop from '../ScrollToTop';

const Overview = () => {
    const [t, i18n] = useTranslation();
    const [lang] = useState();
  
    useEffect(() => {
      i18n.changeLanguage(lang);
    }, [lang, i18n]);
    return (
        <div className={classes.Overview}>
            {/* <ScrollToTop /> */}
            <div className={classes.philosophy}>
                <div className={classes.words}>
                    <h2>{t('philosophy')}</h2>
                    {/* <span style={{fontFamily: 'Ubuntu'}}>{t('etpcont1')}<span style={{color: '#ff2672'}}>e</span>nfty</span>は、日本における “エンジニアリング” の常識を変え、宇宙・工学・プログラミングの輪を広げることを目的としています。 */}
                    {t('philosophy_text')}
                </div>
            </div>

            <div className={classes.background}>
                <div className={classes.left}>
                    <h2>{t('background')}</h2>
                </div>
                <div className={classes.right}>

                    {t('background1_text')}
                    <BreakCreator num={2}/>
                    {t('background2_text')}
                    <BreakCreator num={1}/>
                    {t('background3_text')}
                    <BreakCreator num={2}/>
                    {t('background4_text')}
                </div>
            </div>
            <div className={classes.meaning}>
                <div className={classes.left}>
                    <h2>
                        {/* <span style={{fontFamily: 'Ubuntu'}}><span style={{color: '#ff2672'}}>e</span>nfty</span>の由来 */}
                        {t('root_title')}
                    </h2>
                </div>
                <div className={classes.right}>
                        {/* <span style={{fontFamily: 'Ubuntu'}}>enfty</span>は、指数関数を意味するネイピア数を無限乗することに由来します。同時に、e∞のeはエンジニアリングの頭文字に因んでいます。 */}
                        {t('root1_text')}
                        <BreakCreator num={2}/>
                        {/* これは、宇宙・工学・プログラミングの無限の可能性から着想を得ており、日本のみならず世界中の人々がその恩恵に預かれたらという思いが込められています。 */}
                        {t('root2_text')}
                </div>
            </div>
            <div className={classes.field}>
                <div className={classes.left}>
                    <h2>
                        {/* <span style={{fontFamily: 'Ubuntu'}}><span style={{color: '#ff2672'}}>e</span>nfty</span>の活動 */}
                        {t('what_we_do_title')}
                    </h2>
                </div>
                <div className={classes.right}>
                        {/* 基礎工学を始めとする工学の様々な分野を、若い世代が楽しくインタラクティブに学習できるプラットフォームの開発と提供を活動軸とします。 */}
                        {t('what_we_do1_text')}
                        <BreakCreator num={2}/>
                        {/* PythonやMATLABといったエンジニアが実際に使用するソフトウェアを組み込んだ教材を通して学べる場をつくります。 */}
                        {t('what_we_do2_text')}
                        <BreakCreator num={2}/>
                        {/* また、本職のエンジニアによる講習・仕事内容の紹介などができるプラットフォームを開発します。 */}
                        {t('what_we_do3_text')}
                </div>
            </div>
            <div className={classes.strength}>
                <div className={classes.left}>
                    <h2>
                        {/* <span style={{fontFamily: 'Ubuntu'}}><span style={{color: '#ff2672'}}>e</span>nfty</span>の強み */}
                        {t('strength_title')}
                    </h2>
                </div>
                <div className={classes.right}>
                        {/* <span style={{fontFamily: 'Ubuntu'}}>enfty</span>はプログラミングの中でも工学観点に特化しているのが強みであり、コースとしては構造学、材料学、熱力学、流体力学、誘導制御、推進学、航空学、軌道学などを検討しています。
                        そして、上記の航空宇宙工学の様々な分野を、授業や書き問題ではなくプログラミングを通して学べるのを強みにします。 */}
                        {t('strength1_text')}
                        <BreakCreator num={2}/>
                        {t('strength2_text')}
                </div>
            </div>
            <div className={classes.missions}>
                <div className={classes.left}>
                    <h2>
                        {/* <span style={{fontFamily: 'Ubuntu'}}><span style={{color: '#ff2672'}}>e</span>nfty</span>の未来 */}
                        {t('future_title')}
                    </h2>
                </div>
                <div className={classes.right}>
                        {/* 知識の詰め込みからより実践的でクリエイティブな教育へ。 */}
                        {/* <span style={{fontFamily: 'Ubuntu'}}>enfty</span>もこの教育改革に沿った教材作りを目指し、学校のカリキュラムに取り組まれることも視野に入れています。 */}
                        {t('future1_text')}
                        <BreakCreator num={2}/>
                        {/* また、世界でプログラミングが注目される中、ほとんどの職場に置いてプログラミングスキルが必須になる時代はそう遠くはありません。
                        日本のプログラミング業界の振興も見据えながら、PythonやMATLABなどの実践的なプログラミングスキルが身に付く教材開発を目指します。 */}
                        {t('future2_text')}
                        <BreakCreator num={2}/>
                        {/* 人々が <span style={{fontFamily: 'Ubuntu'}}>enfty</span> のサービスをより身近に感じることによって、宇宙・工学・プログラミングに興味を持ってもらい、
                        最終的には日本の宇宙産業の活性化にも貢献したいです。 */}
                        {/* t('future3_text') */}
                </div>
            </div>
        </div>
    );
}

export default Overview;