import React, { useState, useEffect } from 'react';
import classes from './Userpage.module.scss';
import UserTabs from './UserTabs/UserTabs';
import MyCourses from './UserTabs/MyCoursesPage/MyCoursesPage';
import MyCommunities from './UserTabs/MyCommunitiesPage/MyCommunitiesPage';
import Categories from './UserTabs/CategoriesPage/CategoriesPage';
import MyAccount from './UserTabs/MyAccountPage/MyAccountPage';
import MyAccountEdit from './UserTabs/MyAccountEditPage/MyAccountEditPage';
import MyPoints from './UserTabs/MyPointsPage/MyPointsPage';
import Settings from './UserTabs/SettingsPage/SettingsPage';
import CreatorLoginPage from './UserTabs/CreatorLoginPage/CreatorLoginPage';
import Logout from './UserTabs/LogoutPage/LogoutPage';
import UserRanking from './UserRanking/UserRanking';
import UserCalendar from './UserCalendar/UserCalendar';
import Course from './Courses/Course/Course';
import { useCookies } from 'react-cookie';
import { Route } from 'react-router-dom';

const Userpage = () => {
  // const [inCourse, setInCourse] = useState(false);
  // const cleanPage = () => {
  //   setInCourse(true);
  // }

  // Remove Auto-Generated creator-token
  const [token, setToken, removeToken] = useCookies(['creator-token'])
  useEffect(() => { 
    removeToken('creator-token', { path: '/userpage' })
    removeToken('inCourse-token', { path: '/userpage' })
  },[])

  return (
  <div className={classes.Userpage}>
    <Route path="/userpage/myCourses" component={MyCourses} />
    <Route path="/userpage/myCommunities" component={MyCommunities} />
    <Route path="/userpage/Categories" component={Categories} />
    <Route path="/userpage/myAccount" component={MyAccount} />
    <Route path="/userpage/myAccountEdit" component={MyAccountEdit} />
    <Route path="/userpage/myPoints" component={MyPoints} />
    <Route path="/userpage/settings" component={Settings} />
    <Route path="/userpage/creatorlogin" component={CreatorLoginPage} />
    <Route path="/userpage/logout" component={Logout} />
    <Route path="/userpage/course" component={Course} />
    
    <React.Fragment>
      <UserTabs />
      <UserRanking />
      <UserCalendar />
    </React.Fragment>

    {/* {!inCourse &&
      <React.Fragment>
        <UserTabs />
        <UserRanking />
        <UserCalendar />
      </React.Fragment>
    }
    {inCourse && 
      <React.Fragment>
        <UserTabs />
        <UserRanking />
        <UserCalendar />
      </React.Fragment>
    } */}

  </div>
  )
}

export default Userpage;