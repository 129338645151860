import React, { Component} from 'react';
import ReactGA from 'react-ga';
import './App.css';
import Homepage from './components/Homepage/Homepage';
import Userpage from './components/Userpage/Userpage';
import Creatorpage from './components/Creatorpage/Creatorpage';
import { BrowserRouter, RouteProps, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import Cookies from 'js-cookie';
import store from './redux/store';


function InitializeReactGA() {
  ReactGA.initialize('UA-180623035-1');
  ReactGA.pageview('/');
  ReactGA.pageview('/overview');
  // ReactGA.pageview('/demo');
  ReactGA.pageview('/blog');
  ReactGA.pageview('/people');
  ReactGA.pageview('/login');
  ReactGA.pageview('/userpage');
  return ( null )
}

class App extends Component {
  // If only running frontend, switch the following to move between pages
  // isUser    = true;
  // isCreator = true;
  isUser    = Cookies.get('user-token') && Cookies.get('user-token') !== "undefined";
  isCreator = Cookies.get('creator-token') && Cookies.get('creator-token') !== "undefined";

  render() {
    return (
      <Provider store={store}>
        <InitializeReactGA/>
        <BrowserRouter>
          <React.Fragment>
          { (!this.isUser && !this.isCreator) && <Homepage />}
          { ( this.isUser && !this.isCreator) && <Userpage /> }
          { ( this.isUser &&  this.isCreator) && <Creatorpage /> }
          </React.Fragment>
        </BrowserRouter>
      </Provider>
    );
  }
}

export default App;
