import React, { useState, useEffect } from 'react';
import classes from './TermsModal.module.scss';
import BreakCreator from '../../../../../utils/BreakCreator/BreakCreator';
import { useTranslation　} from 'react-i18next';

const TermsModal = (props) => {
  const [t, i18n] = useTranslation();
  const [lang] = useState();

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang, i18n]);

  const showHideClassName  = props.show ? classes.show : classes.hide;
  return (
    <React.Fragment>
      <div className={showHideClassName}>
        <div className={classes.modal} onClick={props.handleClose ? props.handleClose : props.show}>
          <section className={classes.box} onClick={props.handleClose ? props.handleClose : props.show}>
            <BreakCreator num={1}/>
            <h1>{t('terms_title')}</h1>
            <BreakCreator num={2}/>
            {t('terms_text')}
            <BreakCreator num={2}/>
            <h3>{t('terms_1')}</h3>
            {t('terms_1_text')}
            <BreakCreator num={2}/>
            <h3>{t('terms_2')}</h3>
            {t('terms_2_text')}
            <BreakCreator num={2}/>
            <h3>{t('terms_3')}</h3>
            {t('terms_3_text')}
            <BreakCreator num={2}/>
            <h3>{t('terms_4')}</h3>
            {t('terms_4_text')}
            <BreakCreator num={2}/>
            <h3>{t('terms_5')}</h3>
            {t('terms_5_text')}
            <BreakCreator num={2}/>
          </section>
        </div>
      </div>
    </React.Fragment>
  )
}

export default TermsModal;