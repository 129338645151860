import React from 'react';
import classes from './MyCommunitiesPage.module.scss';

const MyCommunities = () => {
  return (
    <div className={classes.myCommunities}>
      <h1>Coming soon!</h1>
      <p>You can interact with your cohorts inside communities.</p>
    </div>
  )
}

export default MyCommunities;
